// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer{
    border-top: var(--border-top);
}
.footer a {
    color: var(--text-color);
  }
  
  .footer a:hover {
    color: var(--text-color);
  }
  
  .footer .badge {
    font-size: 0.8rem;
    color: var(--text-color);
    background-color: var(--background-color);
  }
  .footer a img{
    color: #fff;
  }
`, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;AACA;IACI,wBAAwB;EAC1B;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,iBAAiB;IACjB,wBAAwB;IACxB,yCAAyC;EAC3C;EACA;IACE,WAAW;EACb","sourcesContent":[".footer{\n    border-top: var(--border-top);\n}\n.footer a {\n    color: var(--text-color);\n  }\n  \n  .footer a:hover {\n    color: var(--text-color);\n  }\n  \n  .footer .badge {\n    font-size: 0.8rem;\n    color: var(--text-color);\n    background-color: var(--background-color);\n  }\n  .footer a img{\n    color: #fff;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
