// LandingSection.js
import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import styles from "./styles.module.css"
import { hero, heroDark, rightArrow } from "../../../../_constants/images";
import { ThemeContext } from "../../../../_common/ThemeContext";
import { isMobile } from "../../../../_constants/constant";

const Hero = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);
    return (
        <Container fluid className={`py-5 ${!isMobile && styles['hero']}`}>
        <Row className="align-items-center">
          {/* Text Section */}
          <Col xs={12} md={6} className="text-center text-md-start px-4">
            <h2 className="display-5">
            One Platform, <br />
              <span className="text-primary fw-bold">Endless Practice for Every Exam</span>
            </h2>
            <p className="lead my-3">
            Learn &nbsp; <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Practice &nbsp;  <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Improve &nbsp;  <img className={`${styles['icon']}`} src={rightArrow} alt="" /> &nbsp; Succeed
            </p>
            <p>Start your preparation for selections. For Free!</p>
            <div className="my-3">
              <Button variant="success" className="me-2 mb-2">Get Started For Free</Button>
              {/* <Button variant="outline-dark" className="me-2 mb-2">
                <img src="https://img.icons8.com/color/48/000000/google-play.png" alt="Google Play" width="24" className="me-1" />
                Google Play
              </Button>
              <Button variant="outline-dark" className="mb-2">
                <img src="https://img.icons8.com/ios-filled/50/000000/mac-os--v1.png" alt="App Store" width="24" className="me-1" />
                App Store
              </Button> */}
            </div>
          </Col>
          
          {/* Image Section */}
          <Col xs={12} md={6} className="d-flex justify-content-center mt-4 mt-md-0">
          <img
                        src={theme == "light" ? hero : heroDark}
                        alt="Exam Preparation"
                        className={`img-fluid ${styles['hero-img']}`}
                        style={{ maxHeight: "400px" }}
                    />
          </Col>
        </Row>
  
        {/* Stats Section */}
        <Row className="justify-content-center text-center my-5">
          <Col xs={6} sm={4} md={3} lg={3} className={`mb-3 ${styles['info']}`}>
            <div className={`p-3 border rounded shadow-sm ${styles['info']}`}>
              <h5 className="fw-bold">Registered Students</h5>
              <p className="text-success fs-5">10+ Lakhs</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} className={`mb-3 ${styles['info']}`}>
            <div className={`p-3 border rounded shadow-sm ${styles['info']}`}>
              <h5 className="fw-bold">Questions Attempted</h5>
              <p className="text-warning fs-5">10+ Crores</p>
            </div>
          </Col>
          <Col xs={6} sm={4} md={3} lg={3} className={`mb-3 ${styles['info']}`}>
            <div className={`p-3 border rounded shadow-sm ${styles['info']}`}>
              <h5 className="fw-bold">Quizzes Attempted</h5>
              <p className="text-info fs-5">1+ Crore</p>
            </div>
          </Col>
          {/* <Col xs={6} sm={4} md={3} lg={2} className={`mb-3 ${styles['info']}`}>
            <div className="p-3 border rounded shadow-sm">
              <h5 className="fw-bold">Classes Attended</h5>
              <p className="text-danger fs-5">5.5+ Crore</p>
            </div>
          </Col> */}
        </Row>
      </Container>
    );
};

export default Hero;
