import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'; // Optional CSS for custom styles
import { facebookIcon, instagramIcon, linkedinIcon, logo } from '../../../_constants/images';

const Footer = () => {
  return (
    <footer className="footer mt-5 p-4 ">
      <div className="container">
        <div className="row"><img src={logo} alt="ExamBolt Logo" style={{ width: '150px' }} /> {/* Replace 'logo.png' with actual logo path */}</div>
        <div className="row">
          {/* Logo and Contact Information */}
          <div className="col-lg-4 col-md-6 mb-4">
            <p className="mt-3">
              <strong>ExamBolt</strong><br />
                Lucknow, Uttar Pradesh, India, 226020
            </p>
            <p>
              <a href="mailto:info@exambolt.in" className="text-decoration-none">info@exambolt.in</a><br />
              {/* Toll Free: 1800 203 0577<br /> */}
              Office Hours: <br/> 10 AM to 7 PM (all 7 days)
            </p>
          </div>

          {/* Company Links */}
          <div className="col-lg-2 col-md-3 mb-4">
            <h5 className="mb-3">Company</h5>
            <ul className="list-unstyled">
              <li><a href="#" className="text-decoration-none ">About us</a></li>
              <li><a href="/contact-us" className="text-decoration-none ">Contact Us</a></li>
              {/* <li><a href="#" className="text-decoration-none ">Careers <span className="badge">We are hiring</span></a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Teach Online on Testbook</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Media</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Sitemap</a></li> */}
            </ul>
          </div>

          {/* Product Links */}
          <div className="col-lg-2 col-md-3 mb-4">
            <h5 className="mb-3">Products</h5>
            <ul className="list-unstyled">
              {/* <li><a href="#" className="text-decoration-none ">Test Series</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Live Tests and Quizzes</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Testbook Pass</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Online Videos</a></li> */}
              <li><a href="#" className="text-decoration-none ">Practice</a></li>
              <li><a href="#" className="text-decoration-none ">Topics</a></li>
              {/* <li><a href="#" className="text-decoration-none ">Live Classes</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Blog</a></li> */}
              <li><a href="#" className="text-decoration-none ">Refer & Earn</a></li>
              {/* <li><a href="#" className="text-decoration-none ">Books</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Exam Calendar</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">GK & CA</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Teacher Training Program</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Doubts</a></li> */}
              {/* <li><a href="#" className="text-decoration-none ">Hire from SkillAcademy</a></li> */}
            </ul>
          </div>

          {/* App and Social Links */}
          <div className="col-lg-4 col-md-6 mb-4">
            {/* <h5 className="mb-3">Our App</h5>
            <div className="mb-2">
              <a href="#" className="btn btn-dark btn-sm mb-2">
                <img src="appstore.png" alt="App Store" style={{ width: '120px' }} />
              </a>
            </div>
            <div className="mb-3">
              <a href="#" className="btn btn-dark btn-sm">
                <img src="playstore.png" alt="Google Play" style={{ width: '120px' }} />
              </a>
            </div> */}
            <h5 className="mb-3">Follow us on</h5>
            <div className="d-flex">
              <a href="https://www.facebook.com/people/ExamBolt/61567350467248/" target='_blank' className=" me-3"><img src={facebookIcon} alt="" /></a>
              {/* <a href="#" className=" me-3"><i className="fab fa-twitter"></i></a> */}
              <a href="https://www.linkedin.com/company/exambolt/" target='_blank' className=" me-3"><img src={linkedinIcon} alt="" /></a>
              <a href="https://www.instagram.com/exam.bolt/" target='_blank' className=" me-3"><img src={instagramIcon} alt="" /></a>
              {/* <a href="#" className=" me-3"><i className="fab fa-youtube"></i></a> */}
              {/* <a href="#" className=""><i className="fas fa-question-circle"></i></a> */}
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="text-center mt-4">
          <p className="mb-0">&copy; 2024 ExamBolt: All rights reserved</p>
          <div>
            <a href="/contact-us" className="text-decoration-none  me-3">Contact Us</a>
            <a href="/terms-and-conditions" className="text-decoration-none  me-3">Terms & Conditions</a>
            <a href="/privacy-policy" className="text-decoration-none ">Privacy Policy</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
