import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../../CommonComponents/Header/Header";
import styles from "./styles.module.css"
import { elogo } from "../../../_constants/images";
import LoginForm from "../../CommonComponents/Forms/Login";
import Signup from "../../CommonComponents/Forms/Signup";

const Login = (props) => {
    const [isLogin,setIsLogin] = useState(true);
    return (
        <div className={`${styles['login']}`}>
            <Header />
            <Container fluid className={`d-flex justify-content-center align-items-center pt-5 ${styles['container']}`}>
      <Row className="w-100 justify-content-center">
        <Col xs={10} sm={8} md={6} lg={3} className=" shadow p-4 rounded border light-box-shadow">
          <div className="text-center mb-4">
            {/* Logo */}
            <img src={elogo} alt="ExamBolt Logo" width="100" />
            <h5><i>EXAMBOLT</i></h5>
          </div>

          {/* Form */}
          {isLogin==true ? <LoginForm setIsLogin={setIsLogin} isLogin={isLogin}/>:<Signup setIsLogin={setIsLogin} isLogin={isLogin}/>}
            {/* Divider and Social Login */}
            {/* <hr />
            <p className="text-center text-muted">or you can sign in with</p>
            <div className={`d-flex justify-content-center`}>
              <a href="#" className={`text-muted me-3 social-media-back`}>
                <img src="https://img.icons8.com/ios-glyphs/30/000000/google-logo.png" alt="Google" />
              </a>
              <a href="#" className={`text-muted me-3 social-media-back`}>
                <img src="https://img.icons8.com/ios-glyphs/30/000000/github.png" alt="GitHub" />
              </a>
              <a href="#" className={`text-muted me-3 social-media-back`}>
                <img src="https://img.icons8.com/ios-glyphs/30/000000/facebook.png" alt="Facebook" />
              </a>
              <a href="#" className={`text-muted social-media-back`}>
                <img src="https://img.icons8.com/ios-glyphs/30/000000/more.png" alt="More" />
              </a>
            </div> */}
        </Col>
      </Row>
    </Container>
        </div>
    );
}
export default Login;