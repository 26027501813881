// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    background-color: var(--background-color);
  color: var(--text-color);
  }
  .navbar-brand img {
    width: 80px;
  }
  
  .nav-link {
    /* background-color: var(--background-color); */
  color: var(--text-color);
  }
  
  .text-danger {
    color: #dc3545 !important; /* Red color for "Live Tests" and "Live Quizzes" */
  }
  
  .button-warning {
    background-color: #ffc107; /* Yellow color for buttons */
    border: none;
    color: #000 !important;
  }
  .login-btn{
    text-decoration: none !important;
    color: #000;
  }
  
  @media (max-width: 991px) {
    /* Adjustments for mobile and tablet view */
    .navbar-brand img {
      width: 70px;
    }
  }

  `, "",{"version":3,"sources":["webpack://./src/_webapp/CommonComponents/Header/style.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;EAC3C,wBAAwB;EACxB;EACA;IACE,WAAW;EACb;;EAEA;IACE,+CAA+C;EACjD,wBAAwB;EACxB;;EAEA;IACE,yBAAyB,EAAE,kDAAkD;EAC/E;;EAEA;IACE,yBAAyB,EAAE,6BAA6B;IACxD,YAAY;IACZ,sBAAsB;EACxB;EACA;IACE,gCAAgC;IAChC,WAAW;EACb;;EAEA;IACE,2CAA2C;IAC3C;MACE,WAAW;IACb;EACF","sourcesContent":[".navbar {\n    background-color: var(--background-color);\n  color: var(--text-color);\n  }\n  .navbar-brand img {\n    width: 80px;\n  }\n  \n  .nav-link {\n    /* background-color: var(--background-color); */\n  color: var(--text-color);\n  }\n  \n  .text-danger {\n    color: #dc3545 !important; /* Red color for \"Live Tests\" and \"Live Quizzes\" */\n  }\n  \n  .button-warning {\n    background-color: #ffc107; /* Yellow color for buttons */\n    border: none;\n    color: #000 !important;\n  }\n  .login-btn{\n    text-decoration: none !important;\n    color: #000;\n  }\n  \n  @media (max-width: 991px) {\n    /* Adjustments for mobile and tablet view */\n    .navbar-brand img {\n      width: 70px;\n    }\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
