// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_practice__zvvtP{
    width: 100%;
    height: 100%;
}
.styles_practice__zvvtP>div{
    width: 80%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/Home/Practice/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,UAAU;IACV,YAAY;AAChB","sourcesContent":[".practice{\n    width: 100%;\n    height: 100%;\n}\n.practice>div{\n    width: 80%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"practice": `styles_practice__zvvtP`
};
export default ___CSS_LOADER_EXPORT___;
