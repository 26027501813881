import React, { useEffect, useState } from "react";
import Header from "../../CommonComponents/Header/Header";
import Hero from "./Hero/Hero";
import styles from "./styles.module.css"
import Practice from "./Practice/Practice";
import Footer from "../../CommonComponents/Footer/Footer";

const Home = (props) => {
    return (
        <div className={`${styles['home']}`}>
            <Header />
            <Hero />
            <Practice/>
            <Footer/>
        </div>
    );
}
export default Home;