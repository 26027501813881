import React, { useContext, useState, useEffect } from 'react';
import { elogo, logo } from '../../../_constants/images';
import styles from "./styles.module.css";
import styled from "styled-components";
import { Navbar, Nav, NavDropdown, Button, Container } from 'react-bootstrap';
import "./style.css";
import { ThemeContext } from "../../../_common/ThemeContext";


const Header = () => {
    // const { theme, toggleTheme } = useContext(ThemeContext);
    
    
    const { theme, toggleTheme } = useContext(ThemeContext);
    const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 21px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 21px;
  }

  span:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:checked + span:before {
    transform: translateX(18px);
  }
`;
    return (
        <Navbar expand="lg" className="shadow-sm" sticky="top">
            <Container>
                {/* Left Logo */}
                <Navbar.Brand href="/">
                    <img
                        src={elogo}  // Replace with your logo path
                        alt="ExamBolt Logo"
                    // style={{ width: '100px' }}
                    />
                </Navbar.Brand>

                {/* Toggler/collapsing button for mobile */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                {/* Navbar items inside collapse */}
                <Navbar.Collapse id="basic-navbar-nav">
                    {/* Navigation Links */}
                    <Nav className="ms-auto">
                        <Nav.Link href="#">Practice</Nav.Link>
                        <Nav.Link href="#">Quizzes</Nav.Link>
                        <Nav.Link href="#">Topics</Nav.Link>
                    </Nav>

                    {/* Icons and Buttons */}
                    <Nav className="ms-auto d-flex align-items-center">
                        <i className="bi bi-search me-3" style={{ fontSize: '1.2rem' }}></i> {/* Bootstrap Icon */}
                        <Button variant="warning" className="mb-2 mb-lg-0" style={{marginRight:'15px'}}><a href='/accounts/login' className='login-btn'>Login / Register</a></Button>
                        
                        <Switch>
                            <input
                                type="checkbox"
                                checked={theme === "dark"}
                                onChange={toggleTheme}

                            />
                            <span></span>
                        </Switch>
                        <span>Dark Mode</span>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;