import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../../CommonComponents/Header/Header";
// import "./ContactUs.css";
import Footer from "../../CommonComponents/Footer/Footer";

const Privacy = (props) => {
    return (
        <div>
            <Header/>
            <div className="terms">
                <h2 className="mt-5">Privacy Policy</h2>
                <p className="mt-5 mb-5">Last updated: 10-Nov-2024 <br /><br /><br />
                <h5>General</h5>
By using, accessing, or participating in ExamBolt’s website and services (the "Service"), you agree to the terms of this Privacy Policy. If you do not agree, please refrain from using our Service. We reserve the right to modify this Privacy Policy at any time. Changes will be posted with an effective date on the website, and significant updates will be announced with a 7-day notice. The updated policy becomes effective at the end of the 7-day period or the next time you access the Service.
<br /><br />
<h5>Information We Collect</h5>

When you interact with ExamBolt, we collect two types of information:
<br />
<b>1. User-Submitted Information:</b>  This includes details you provide directly, such as your name, email address, phone number, and profile information.
<br />
<b>2. Usage Information:</b> Automatically collected data, such as your IP address, browser type, and cookies, helps us improve and personalize your experience on the website. You can disable cookies through your browser settings, but this may limit certain features.
<br /><br /><h5>Use of Information</h5>

ExamBolt uses your information to:
<br />
• Provide and enhance the Service. <br />
• Send you notifications about new features, updates, or services we believe may interest you. <br />
• Enable profile personalization and data analytics for optimized user experience. You may opt-out of promotional emails, though we may still send essential account notifications. <br />
<br /><br />
<h5>Sharing Information with Third Parties</h5>

We share personal information only when necessary to offer the Service, comply with legal obligations, or with your consent. For example:
<br /><br />
<b>• Service Providers:</b> We may share information with third-party providers who help operate our Service, like hosting and analytics. <br />
<b>• Push Notifications and Advertising:</b> With your consent, we may collect device identifiers or location data to deliver targeted notifications. You may opt-out via device settings. <br /><br />
<h5>User Control & Opt-Out Options</h5>

<b>• Push Notifications:</b> Disable notifications in your device settings. <br />
<b>• Interest-Based Advertising:</b> Adjust ad preferences on your device (e.g., through Google Ads settings). <br /><br />
<h5>Data Disclosure</h5>

We may disclose personal information if required by law, or to protect ExamBolt’s rights, prevent fraud, or ensure user safety.
<br /><br />
<h5>Acquisition or Change of Ownership</h5>

If ExamBolt undergoes an ownership change, user information may be transferred to the new owner.
<br /><br />
<h5>Links to Other Websites</h5>

Our Service may contain links to external sites. ExamBolt is not responsible for the privacy practices of these websites, and we encourage users to review third-party policies.
                </p>
            </div>
            <Footer/>
        </div>
)}

export default Privacy; 