import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Alert} from "react-bootstrap";
import { apiUrl } from "../../../_constants/config";
const Login = ({isLogin, setIsLogin}) => {
    const [formData, setFormData] = useState({ email: "", password: "" });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Example: Send data to API endpoint (adjust the URL as needed)
    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSuccessMessage("Logged In Successfully!");
        setErrorMessage("");
        // Handle successful login (e.g., redirect or store token)
        // Assuming the API response contains a token and user information
        const { data } = await response.json();

        // Save the token and user details to localStorage
        localStorage.setItem("token", data.access_token);
        // localStorage.setItem("user", JSON.stringify(user));
      } else {
        setErrorMessage("Login failed. Please check your credentials.");
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      setSuccessMessage("");
    }
  };
    return (
<Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmailOrUsername" className="mb-3">
              <Form.Control
                type="text"
                className="border border-radius2"
                placeholder="Username or E-mail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Control
                type="password"
                className="border border-radius2"
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Button variant="dark" type="submit" className="w-100 mb-3">
              Sign In
            </Button>

            {/* Forgot Password / Sign Up Links */}
            <div className="d-flex justify-content-between">
              <a href="#" className={`text-muted`}>Forgot Password?</a>
              <a href="#" onClick={()=>setIsLogin(!isLogin)} className={`text-muted`}>Sign Up</a>
            </div>
            </Form>
    );
}
export default Login;