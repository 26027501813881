import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cssloader from "./_common/cssloader";
import Home from "./_webapp/_Pages/Home/Home";
import { ThemeProvider } from "./_common/ThemeContext";
import Login from "./_webapp/_Pages/Login/Login";
import ContactUs from "./_webapp/_Pages/ContactUs/ContactUs"
import Terms from "./_webapp/_Pages/Terms/Terms";
import Privacy from "./_webapp/_Pages/Privacy/Privacy";
function App() {
  return (
    <ThemeProvider>
    <div className="App">
      <Cssloader />
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} exact />
          <Route path={"/accounts/login"} element={<Login />} exact />
          <Route path={"/contact-us"} element={<ContactUs />} exact />
          <Route path={"/terms-and-conditions"} element={<Terms />} exact />
          <Route path={"/privacy-policy"} element={<Privacy />} exact />
        </Routes>
      </BrowserRouter>
    </div>
    </ThemeProvider>
  );
}

export default App;