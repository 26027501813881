import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Categories from '../../../CommonComponents/Categories/Categories';
import FilterPanel from '../../../CommonComponents/FilterPanel/FilterPanel';
import QuestionTable from '../../../CommonComponents/QuestionTable/QuestionTable';
import styles from "./styles.module.css"

const Practice = (props) => {
  return (
    <div className={`${styles['practice']}`}>
    <Container fluid className="my-3">
      <Row>
        <Col lg={8} xs={12} className="mb-3">
          <FilterPanel />
          <QuestionTable />
        </Col>
        <Col lg={4} xs={12}>
          <Categories />
        </Col>
      </Row>
    </Container>
    </div>
  );
}

export default Practice;