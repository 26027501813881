import React from 'react';
import { Badge, ListGroup } from 'react-bootstrap';

const companies = [
  { name: 'Google', count: 1662 },
  { name: 'Amazon', count: 1714 },
  { name: 'Meta', count: 909 },
  // Add more companies as needed
];

function Categories() {
  return (
    <div className="mb-3">
      <h5>Trending Companies</h5>
      <ListGroup variant="flush">
        {companies.map((company, index) => (
          <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
            {company.name}
            <Badge bg="warning">{company.count}</Badge>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default Categories;
