import React, { useRef } from 'react';
import { Button, Dropdown, DropdownButton, Form, InputGroup } from 'react-bootstrap';
import './FilterPanel.css'; // Import custom CSS
import { isMobile } from '../../../_constants/constant';

function FilterPanel() {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -100, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    }
  };
  const topics = ["Algorithms", "Database", "Shell", "Concurrency", "JavaScript"];
  return (
    <div className="mb-3">
      {isMobile ?
        // Mobile view: Dropdown for topics
        <DropdownButton title="Topics" variant="outline-secondary" className="topics-dropdown">
        {topics.map((topic, index) => (
          <Dropdown.Item key={index}>{topic}</Dropdown.Item>
        ))}
      </DropdownButton>
      :
      <div className="d-flex align-items-center mb-2">
        <Button variant="dark" className="me-3 all-topics">All Topics</Button>
        
        {/* Arrow button for scrolling left */}
        <Button variant="outline-secondary" onClick={scrollLeft} className="me-2 arrow-button">{"<<"}</Button>

        {/* Scrollable topic buttons */}
        <div className="scroll-container" ref={scrollRef}>
        {topics.map((topic, index) => (
                  <Button key={index} variant="outline-primary" className="me-2 topic-button">{topic}</Button>
                ))}
        </div>

        {/* Arrow button for scrolling right */}
        <Button variant="" onClick={scrollRight} className="ms-2 arrow-button">{">>"}</Button>
      </div>}
      
      {/* Filters and Search */}
      <InputGroup className="mb-3">
        <DropdownButton title="Lists" variant="outline-secondary">
          <Dropdown.Item>All</Dropdown.Item>
          <Dropdown.Item>Favorite</Dropdown.Item>
        </DropdownButton>
        <DropdownButton title="Difficulty" variant="outline-secondary">
          <Dropdown.Item>Easy</Dropdown.Item>
          <Dropdown.Item>Medium</Dropdown.Item>
          <Dropdown.Item>Hard</Dropdown.Item>
        </DropdownButton>
        <DropdownButton title="Status" variant="outline-secondary">
          <Dropdown.Item>All</Dropdown.Item>
          <Dropdown.Item>Solved</Dropdown.Item>
          <Dropdown.Item>Unsolved</Dropdown.Item>
        </DropdownButton>
        <Form.Control placeholder="Search questions" />
        <Button variant="success">Pick One</Button>
      </InputGroup>
    </div>
  );
}

export default FilterPanel;
