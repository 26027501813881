// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
}

.text-primary{
    color:#EE7C79 !important;
}
.text-muted{
    color: var(--text-muted-color) !important;
}

p{
    margin-bottom: 0px ;
}

.border{
    border: 1px solid #b0b0b0 !important;
}

.border-radius2{
    border-radius: 2px;
}
.border-radius4{
    border-radius: 4px;
}
.border-radius8{
    border-radius: 8px;
}
.social-media-back{
    background-color: #ffffff;
    border-radius: 50%;

}
.light-box-shadow{
    box-shadow: var(--light-box-shadow);
}
.terms{
    width: 100%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.terms p{
    width: 85%;
}
.red{
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/_common/style.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;EACZ,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;IACI,wBAAwB;AAC5B;AACA;IACI,yCAAyC;AAC7C;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;;AAEtB;AACA;IACI,mCAAmC;AACvC;AACA;IACI,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,UAAU;AACd;AACA;IACI,UAAU;AACd","sourcesContent":["html,\nbody {\n  width: 100%;\n  height: 100%;\n  background-color: var(--background-color);\n  color: var(--text-color);\n}\n\n.text-primary{\n    color:#EE7C79 !important;\n}\n.text-muted{\n    color: var(--text-muted-color) !important;\n}\n\np{\n    margin-bottom: 0px ;\n}\n\n.border{\n    border: 1px solid #b0b0b0 !important;\n}\n\n.border-radius2{\n    border-radius: 2px;\n}\n.border-radius4{\n    border-radius: 4px;\n}\n.border-radius8{\n    border-radius: 8px;\n}\n.social-media-back{\n    background-color: #ffffff;\n    border-radius: 50%;\n\n}\n.light-box-shadow{\n    box-shadow: var(--light-box-shadow);\n}\n.terms{\n    width: 100%;\n    text-align: justify;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.terms p{\n    width: 85%;\n}\n.red{\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
