import React from 'react';
import { Table } from 'react-bootstrap';

const questions = [
  { title: 'Two Sum', acceptance: '54.1%', difficulty: 'Easy' },
  { title: 'Add Two Numbers', acceptance: '44.5%', difficulty: 'Medium' },
  { title: 'Longest Substring Without Repeating Characters', acceptance: '35.7%', difficulty: 'Medium' },
  // Add more questions as needed
];

function QuestionTable() {
  return (
    <Table responsive bordered hover>
      <thead>
        <tr>
          <th>Status</th>
          <th>Title</th>
          <th>Acceptance</th>
          <th>Difficulty</th>
          <th>Frequency</th>
        </tr>
      </thead>
      <tbody>
        {questions.map((q, index) => (
          <tr key={index}>
            <td>🗓️</td>
            <td>{q.title}</td>
            <td>{q.acceptance}</td>
            <td className={q.difficulty === 'Easy' ? 'text-success' : q.difficulty === 'Medium' ? 'text-warning' : 'text-danger'}>
              {q.difficulty}
            </td>
            <td>🔒</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default QuestionTable;
