// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_login__NgTIn{
    height: 100%;
    width: 100%;
}
.styles_login__NgTIn h5{
    margin-left: -20px;
}
.styles_login__NgTIn .styles_container__jOaSW button{
    background-color: var(--dark-background-color);
    color: var(--dark-color);
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/Login/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,kBAAkB;AACtB;AACA;IACI,8CAA8C;IAC9C,wBAAwB;AAC5B","sourcesContent":[".login{\n    height: 100%;\n    width: 100%;\n}\n.login h5{\n    margin-left: -20px;\n}\n.login .container button{\n    background-color: var(--dark-background-color);\n    color: var(--dark-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `styles_login__NgTIn`,
	"container": `styles_container__jOaSW`
};
export default ___CSS_LOADER_EXPORT___;
