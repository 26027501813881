// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styling for Contact Us section */
  h2 {
    font-size: 2rem;
  }
  
  /* p {
    font-size: 1rem;
    color: #6c757d;
  } */
  
  .card {
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .btn-primary {
    background-color: #6c3dc9;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #582db5;
  }

.form-control{
  background-color: var(--background-color);
  color: var(--text-color);
}
.form-control::placeholder{
  color: var(--text-color);
}

.form-control:focus{
  background-color: var(--background-color);
  color: var(--text-color);
}

.no-resize {
  resize: none; /* Prevents resizing of the textarea */
}`, "",{"version":3,"sources":["webpack://./src/_webapp/_Pages/ContactUs/ContactUs.css"],"names":[],"mappings":"AAAA,0CAA0C;EACxC;IACE,eAAe;EACjB;;EAEA;;;KAGG;;EAEH;IACE,yCAAyC;IACzC,wBAAwB;EAC1B;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;AAEF;EACE,yCAAyC;EACzC,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,YAAY,EAAE,sCAAsC;AACtD","sourcesContent":["/* Custom styling for Contact Us section */\n  h2 {\n    font-size: 2rem;\n  }\n  \n  /* p {\n    font-size: 1rem;\n    color: #6c757d;\n  } */\n  \n  .card {\n    background-color: var(--background-color);\n    color: var(--text-color);\n  }\n  \n  .btn-primary {\n    background-color: #6c3dc9;\n    border: none;\n  }\n  \n  .btn-primary:hover {\n    background-color: #582db5;\n  }\n\n.form-control{\n  background-color: var(--background-color);\n  color: var(--text-color);\n}\n.form-control::placeholder{\n  color: var(--text-color);\n}\n\n.form-control:focus{\n  background-color: var(--background-color);\n  color: var(--text-color);\n}\n\n.no-resize {\n  resize: none; /* Prevents resizing of the textarea */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
