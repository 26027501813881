import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../../CommonComponents/Header/Header";
// import "./ContactUs.css";
import Footer from "../../CommonComponents/Footer/Footer";

const Terms = (props) => {
    return (
        <div>
            <Header/>
            <div className="terms">
                <h2 className="mt-5">Terms & Conditions</h2>
            <p className="mt-5 mb-5">Last updated: 10-Nov-2024 <br /><br /><br />

Welcome to ExamBolt! The following terms and conditions govern your access to and use of the ExamBolt website ("Website") and related services (collectively referred to as the "Service"), operated by ExamBolt ("ExamBolt", "we", or "us"). By accessing or using any part of the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. Please review these terms periodically, as we may modify, update, or change them at any time. If we make changes, we will post a notice on our Website for at least 7 days, and changes will become effective after this period or when you first access the Service after the updates. If you do not agree to abide by these Terms and Conditions, please refrain from using the Service.
<br /><br />
<h5>1. Description of Website and Service</h5>
The Service enables users to prepare for examinations online, offering resources like (a) digital and (b) printed test series, as well as (c) evaluation services. ExamBolt may change, suspend, or discontinue any aspect of the Service at any time without notice.
<br /><br />
<h5>2. Registration</h5>
To use certain features of the Service, you must register and provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account information, and for all activities conducted through your account. By registering, you consent to be contacted by ExamBolt via phone, SMS, or email with updates, offers, and notifications. You may also be required to provide examination registration information for internal evaluation purposes. Failure to provide this information may result in restricted access to some services, and refunds will not be provided in such cases.
<br /><br />
<h5>3. Subscriptions</h5>
ExamBolt offers monthly and annual subscription options. Payments for subscriptions are processed by third-party platforms or directly on the Website. Subscription fees are non-refundable, and subscriptions auto-renew unless canceled. You may cancel your subscription as outlined in the Cancellation Policy.
<br /><br />
<h5>4. Free Trial and Automatic Renewal</h5>
We may offer a free trial, which will automatically convert to a paid subscription unless canceled at least 24 hours before the trial ends. By signing up, you authorize ExamBolt to charge your account on a recurring basis unless canceled.
<br /><br />
<h5>5. Representations and Warranties</h5>
You agree to use the Service in compliance with applicable laws and regulations, and you warrant that you have the rights to any materials you submit to ExamBolt.
<br /><br />
<h5>6. Prohibited Use</h5>
You may not use the Service to post or share any content that is defamatory, harassing, offensive, or illegal. Content that violates these guidelines may result in account suspension or termination.
<br /><br />
<h5>7. Indemnification</h5>
You agree to indemnify and hold ExamBolt, its officers, employees, and agents harmless from any claims, damages, losses, or expenses arising from your use of the Service.
<br /><br />
<h5>8. No Warranty</h5>
The Service is provided "as is" without warranties of any kind. ExamBolt disclaims any warranties of merchantability, fitness for a particular purpose, or non-infringement.
<br /><br />
<h5>9. Limitation of Liability</h5>
ExamBolt is not liable for any indirect, incidental, or consequential damages arising from the use or inability to use the Service. In any event, ExamBolt's liability is limited to the amount paid by you for the Service in the past 12 months.
<br /><br />
<h5>10. Termination</h5>
ExamBolt reserves the right to terminate your access to the Service at any time for any reason. You may also terminate your account at any time following instructions provided on the Website.
<br /><br />
<h5>11. Intellectual Property</h5>
All content on the Service, including designs, text, graphics, and data, is the proprietary property of ExamBolt. Unauthorized copying or distribution is prohibited.
<br /><br />
<h5>12. Trademarks</h5>
The "ExamBolt" logo and name, along with other trademarks and service marks on the Service, are owned by ExamBolt. You may not use these without our express permission.
<br /><br />
<h5>13. Privacy Policy</h5>
Our Privacy Policy, available at [ExamBolt's Privacy Policy URL], governs the collection and use of your personal information. By using the Service, you consent to the terms of our Privacy Policy.
<br /><br />
<h5>14. Cancellation and Refund Policy</h5>
Cancellation: You may cancel your subscription within 24 hours of placing the order if the product has not yet been accessed or shipped.
Refunds: Refunds are only applicable for products that have not been accessed or dispatched. Eligible refunds will be processed within 14 business days.
<br /><br />
<h5>15. Copyright Infringement Notice</h5>
If you believe your copyright is being infringed upon, you may submit a copyright infringement notice by providing the following details:
<br /><br />
<h5>Your contact information</h5>
A description of the infringing material and its location on the Service
A statement of good faith belief that the use is unauthorized
<br /><br />
<h5>16. Governing Law and Jurisdiction</h5>
Please consult your legal counsel for further details or see Section 51 of The Copyright Act, 1957. Testbook's Agent for Notice of claims of copyright infringement can be reached as follows: By email: info@exambolt.in
<br /><br />
<h5>17. Miscellaneous</h5>
These Terms and Conditions constitute the entire agreement between ExamBolt and you. If any provision is deemed unenforceable, it will be limited to the extent necessary, and all other provisions will remain in full force. ExamBolt reserves the right to assign its rights or obligations to third parties.
<br /><br />
Thank you for choosing ExamBolt.</p>
</div>
            <Footer/>
        </div>
)}

export default Terms; 